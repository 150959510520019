import { useEffect, useState } from "react";
import { useSelectedCompanyContext } from "../../../../Shared/SelectedCompanyContext";
import { IcebergButton } from "@tradesolution/iceberg-ui-react";
import { Alert, Form, InputGroup, Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { TemplateData } from "../../TemplateData";
import { htmlToImageToPdf } from "../PdfService/pdfService";

interface PdfRequest {
  fileName: string;
}

interface Props {    
	title: string;
  templateData: TemplateData;
  templateId: number;
  orientation?: 'portrait' | 'landscape' | null;
  }
  
  function PdfExportModal(props: Props) {
    const { register, handleSubmit, formState: { errors }, reset} = useForm<PdfRequest>()	
    const { selectedCompany } = useSelectedCompanyContext();
    const [show, setShow] = useState(false);
    const handleClose = () => {setShow(false); setGeneratingPdf(false);};
    const handleShow = () => setShow(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [generatingPdf, setGeneratingPdf] = useState(false);    
    
    const loadData = async () => {          
          reset({
          fileName: props.title      
          });
      };
    
      useEffect(() => {		
        loadData();
      }, [props.title]);
        
    const onSubmit: SubmitHandler<PdfRequest> = async (data: PdfRequest) => {
      setErrorMessage('');
      setGeneratingPdf(true);
      const pdf = await htmlToImageToPdf(
        props.templateId,
        props.templateData,
        selectedCompany.name,
        props.orientation
      );
      
      if (!pdf) {
        setGeneratingPdf(false);
        setErrorMessage('Noe gikk galt under generering av PDF');
        return;
      }
      pdf.save(data.fileName + '.pdf');
      setGeneratingPdf(false);
      handleClose();
	  };
  
    return (
      <>
        <IcebergButton className='me-2' icon='export' variant='outline-primary' onClick={handleShow}>Last ned PDF</IcebergButton>
        <Modal show={show} onHide={handleClose} size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>Last ned PDF</Modal.Title>            
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                  <Form.Label>Filnavn</Form.Label>
                  <InputGroup>
                      <Form.Control                        
                          type="text" 
                          isInvalid={!!errors.fileName}
                          {...register("fileName", { required: "Filnavn er påkrevd", maxLength: { value: 250, message:"Filnavn kan max ha 250 tegn"}})} />
                          <Form.Control.Feedback type="invalid">
                          {errors.fileName && <span>{errors.fileName.message}</span>}
                      </Form.Control.Feedback>
                  </InputGroup>
              </Form.Group>
              {errorMessage && (
              <Alert variant="danger">
                {errorMessage}
              </Alert>
            )}                               
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <>
                <IcebergButton variant="outline-primary" icon="close" className="col" onClick={handleClose}>
                    Avbryt
                </IcebergButton>
                <IcebergButton isLoading={generatingPdf} variant="primary" icon="export" className="col" onClick={handleSubmit(onSubmit)}>
                  Last ned PDF
                </IcebergButton>  
            </>
            </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default PdfExportModal;
