
import "../TemplateBaseStyling.scss";
import ProductImage from "../components/ProductImage";
import Logo from "../components/Logo";
import { TemplateData } from "../../TemplateData";
import ProductTable from "./ProductTable";
import { generateBarcode } from "../../../../Shared/BarcodeGenerator";
import MovableElement from "../../../../components/MovableElement";
import { AutoTextSize } from "auto-text-size";
import { useEffect, useState } from "react";
import { IdentifierType, ProductData } from "../../ProductItem";
import { Template } from "../../Components/LeftCol";

interface Props {
  templateData: TemplateData;
  selectedTemplate?: Template | undefined;
}

const ImageTableTemplate = (props: Props) => {
  const defaultTableData: ProductData = {
    identiferType: IdentifierType.EPD_NR,
    products: [
      {
        produktnavn: "Produkt 1",
        epdNr: "123456",
        basegtins: [],
        strekkodeBase64Url: generateBarcode("123456"),
        unitSmallPreviewImageGuid: undefined,
        angle: undefined,
        mediaID: undefined,
        orderableUnitGtin: undefined,
        barcodeInput: "EPD NR",
        imageGtin: "123456",
        totalBaseUnitInOrderableUnit: 1,
      },
    ]
  };

  const fontFamily = props.templateData.designData.fontFamily
    ? props.templateData.designData.fontFamily
    : "inherit";

  const [titleSize, setTitleSize] = useState({ width: 300, height: 50 });
  const [subTitleSize, setSubTitleSize] = useState({ width: 300, height: 30 });

  // reset sizes and positions when template changes
  useEffect(() => {
    setTitleSize({ width: 300, height: 50 });
    setSubTitleSize({ width: 300, height: 30 });
  }, [props.selectedTemplate]);

  return (
    <div
      id="template"
      className="template-container"
      style={{
        color: props.templateData.designData.fontColor ?? "#000",
        backgroundColor:
          props.templateData.designData.backgroundColor ?? "#fff",
        fontFamily: fontFamily,
        backgroundImage: props.templateData.designData.backgroundImageUrl
          ? `url(${props.templateData.designData.backgroundImageUrl})`
          : undefined,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >     
      {props.templateData.showDemoText && (
        <h1 className="watermark">Demo</h1>
      )}
      <div className="dynamic-area">
        <div className="template-logo-placeholder mt-5">
          <Logo
            fontFamily={fontFamily}
            fontColor={props.templateData.designData.fontColor ?? "#000"}
            logoText={props.templateData.designData.logoText}
            logoFileName={props.templateData.designData.logoFileName}
            fontSize={props.templateData.designData.logoFontSize ?? 16}
            templateId={props.selectedTemplate?.id}
          />
          {props.templateData.designData.title && (
          <div
            className="mt-3"
            style={{ height: "50px", zIndex: "1000" }}
          >
            <MovableElement size={titleSize} top={50}>
              <AutoTextSize
                mode="box"
                minFontSizePx={10}
                maxFontSizePx={100}
                fontSizePrecisionPx={0.1}
                style={{
                  fontFamily: fontFamily,
                  color: props.templateData.designData.fontColor ?? "#000",
                }}
              >
                <div contentEditable>{props.templateData.designData.title}</div>
              </AutoTextSize>
            </MovableElement>
          </div>
        )}
        </div>

        <div className="section mt-3 product-image-section">
          {/* Template 1 */}
          {(!props.selectedTemplate || props.selectedTemplate.id === 1) && (
            <ProductImage
              mediaID={
                props.templateData.productData &&
                props.templateData.productData.products.length > 0
                  ? props.templateData.productData.products[0].mediaID
                  : null
              }
              heightInPx={500}
              widthInPx={500}
              gtin={
                props.templateData.productData &&
                props.templateData.productData.products.length > 0
                  ? props.templateData.productData.products[0].imageGtin
                  : null
              }
              showPlaceholder={
                props.templateData.productData &&
                props.templateData.productData.products.length === 0
              }
            />
          )}

          {/* Template 2 */}
          {props.selectedTemplate?.id === 2 && (
            <>
              <div
                className="image-row"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <div
                  className="col"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ProductImage
                    mediaID={
                      props.templateData.productData &&
                      props.templateData.productData.products.length > 0
                        ? props.templateData.productData.products[0].mediaID
                        : null
                    }
                    heightInPx={250}
                    widthInPx={250}
                    gtin={
                      props.templateData.productData &&
                      props.templateData.productData.products.length > 0
                        ? props.templateData.productData.products[0].imageGtin
                        : null
                    }
                    showPlaceholder={
                      props.templateData.productData &&
                      props.templateData.productData.products.length === 0
                    }
                  />
                </div>
                <div
                  className="col"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ProductImage
                    mediaID={
                      props.templateData.productData &&
                      props.templateData.productData.products.length > 1
                        ? props.templateData.productData.products[1].mediaID
                        : null
                    }
                    heightInPx={250}
                    widthInPx={250}
                    gtin={
                      props.templateData.productData &&
                      props.templateData.productData.products.length > 1
                        ? props.templateData.productData.products[1].imageGtin
                        : null
                    }
                    showPlaceholder={
                      props.templateData.productData &&
                      props.templateData.productData.products.length === 0
                    }
                  />
                </div>
              </div>
              <div
                className="image-center mt-3"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="col"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ProductImage
                    mediaID={
                      props.templateData.productData &&
                      props.templateData.productData.products.length > 2
                        ? props.templateData.productData.products[2].mediaID
                        : null
                    }
                    heightInPx={250}
                    widthInPx={250}
                    gtin={
                      props.templateData.productData &&
                      props.templateData.productData.products.length > 2
                        ? props.templateData.productData.products[2].imageGtin
                        : null
                    }
                    showPlaceholder={
                      props.templateData.productData &&
                      props.templateData.productData.products.length === 0
                    }
                  />
                </div>
              </div>
            </>
          )}

          {/* Template 3 */}
          {props.selectedTemplate?.id === 3 && (
            <div
              className="image-grid"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].mediaID
                      : null
                  }
                  heightInPx={245}
                  widthInPx={245}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].mediaID
                      : null
                  }
                  heightInPx={245}
                  widthInPx={245}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[2].mediaID
                      : null
                  }
                  heightInPx={245}
                  widthInPx={245}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[2].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 3
                      ? props.templateData.productData.products[3].mediaID
                      : null
                  }
                  heightInPx={245}
                  widthInPx={245}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 3
                      ? props.templateData.productData.products[3].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
            </div>
          )}

          {/* Template 4 */}
          {props.selectedTemplate?.id === 4 && (
            <div className="row">
              <div
                className="col mr-1"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "5px",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].mediaID
                      : null
                  }
                  heightInPx={500}
                  widthInPx={250}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].mediaID
                      : null
                  }
                  heightInPx={500}
                  widthInPx={250}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
            </div>
          )}

          {/* Template 5 */}
          {props.selectedTemplate?.id === 5 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].mediaID
                      : null
                  }
                  heightInPx={250}
                  widthInPx={500}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].mediaID
                      : null
                  }
                  heightInPx={250}
                  widthInPx={500}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
            </div>
          )}

          {/* Template 6 */}
          {props.selectedTemplate?.id === 6 && (
            <div
              style={{
                position: "relative",
                margin: "0 auto",
                width: "500px",
                height: "500px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].mediaID
                      : null
                  }
                  heightInPx={350}
                  widthInPx={350}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "150px",
                  left: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].mediaID
                      : null
                  }
                  heightInPx={350}
                  widthInPx={350}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
            </div>
          )}

          {/* Template 7 */}
          {props.selectedTemplate?.id === 7 && (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].mediaID
                      : null
                  }
                  heightInPx={500}
                  widthInPx={150}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].mediaID
                      : null
                  }
                  heightInPx={500}
                  widthInPx={150}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[2].mediaID
                      : null
                  }
                  heightInPx={500}
                  widthInPx={150}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[2].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
            </div>
          )}

          {/* Template 8 */}
          {props.selectedTemplate?.id === 8 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].mediaID
                      : null
                  }
                  heightInPx={150}
                  widthInPx={300}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].mediaID
                      : null
                  }
                  heightInPx={150}
                  widthInPx={300}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[2].mediaID
                      : null
                  }
                  heightInPx={150}
                  widthInPx={300}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[2].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
            </div>
          )}

          {/* Template 9 */}
          {props.selectedTemplate?.id === 9 && (
            <div
              style={{
                position: "relative",
                width: "500px",
                height: "500px",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "250px",
                  height: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "1",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].mediaID
                      : null
                  }
                  heightInPx={250}
                  widthInPx={250}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "125px",
                  left: "125px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "2",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].mediaID
                      : null
                  }
                  heightInPx={250}
                  widthInPx={250}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "250px",
                  left: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: "3",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[2].mediaID
                      : null
                  }
                  heightInPx={250}
                  widthInPx={250}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[2].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
            </div>
          )}

          {/* Template 10 */}
          {props.selectedTemplate?.id === 10 && (
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].mediaID
                      : null
                  }
                  heightInPx={500}
                  widthInPx={150}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].mediaID
                      : null
                  }
                  heightInPx={500}
                  widthInPx={150}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[2].mediaID
                      : null
                  }
                  heightInPx={500}
                  widthInPx={150}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[2].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 3
                      ? props.templateData.productData.products[3].mediaID
                      : null
                  }
                  heightInPx={500}
                  widthInPx={150}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 3
                      ? props.templateData.productData.products[3].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
            </div>
          )}

          {/* Template 11 */}
          {props.selectedTemplate?.id === 11 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].mediaID
                      : null
                  }
                  heightInPx={100}
                  widthInPx={300}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 0
                      ? props.templateData.productData.products[0].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].mediaID
                      : null
                  }
                  heightInPx={100}
                  widthInPx={300}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 1
                      ? props.templateData.productData.products[1].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[1].mediaID
                      : null
                  }
                  heightInPx={100}
                  widthInPx={300}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 2
                      ? props.templateData.productData.products[2].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProductImage
                  mediaID={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 3
                      ? props.templateData.productData.products[3].mediaID
                      : null
                  }
                  heightInPx={100}
                  widthInPx={300}
                  gtin={
                    props.templateData.productData &&
                    props.templateData.productData.products.length > 3
                      ? props.templateData.productData.products[3].imageGtin
                      : null
                  }
                  showPlaceholder={
                    props.templateData.productData &&
                    props.templateData.productData.products.length === 0
                  }
                />
              </div>
            </div>
          )}
          {/* Template 12 */}
          {props.selectedTemplate?.id === 12 && <div style={{ height: "500px" }}></div>}
        </div>

        {props.templateData.designData.subtitle && (
          <div
            className="section mt-3"
            style={{ height: "30px", zIndex: "1000" }}
          >
            <MovableElement size={subTitleSize}>
              <AutoTextSize
                mode="box"
                minFontSizePx={10}
                maxFontSizePx={100}
                fontSizePrecisionPx={0.1}
                style={{
                  fontFamily: fontFamily,
                  color: props.templateData.designData.fontColor ?? "#000",
                }}
              >
                <div contentEditable>
                  {props.templateData.designData.subtitle}
                </div>
              </AutoTextSize>
            </MovableElement>
          </div>
        )}

        <div className="section mt-3 product-table-section">
          <ProductTable            
            productData={              
              props.templateData.productData &&
              props.templateData.productData.products.length > 0
                ? props.templateData.productData
                : defaultTableData
            }
            topItems={props.selectedTemplate?.maxProductCount}
          ></ProductTable>
        </div>
      </div>

      <div className="section mt-3 contact-info-section">
        {props.templateData.contactInfo.name && (
          <div className="text-center">
            {props.templateData.contactInfo.name}
          </div>
        )}
        {props.templateData.contactInfo.email && (
          <div className="text-center">
            {props.templateData.contactInfo.email}
          </div>
        )}
        {props.templateData.contactInfo.phone && (
          <div className="text-center">
            {props.templateData.contactInfo.phone}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageTableTemplate;
