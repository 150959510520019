import { useEffect, useState } from "react";
import { generateBarcode } from "../../../../Shared/BarcodeGenerator";
import { IdentifierType, ProductData, ProductItem } from "../../ProductItem";
import { Card, Col, Row } from "react-bootstrap";
import ProductImage from "../components/ProductImage";
import ThumbnailProductImage from "../components/ThumbnailProductImage";

interface Props {
    productData: ProductData;
    topItems?: number; // Specify the number of top items to return
}

const generateBarcodeIfMissing = (product: ProductItem, identiferType: IdentifierType): ProductItem => {
    if (!product.strekkodeBase64Url) {
        switch (identiferType) {
            case IdentifierType.GTIN:
                product.strekkodeBase64Url = generateBarcode(product.orderableUnitGtin);
                break;
            case IdentifierType.COOP_NR:
                if (product.coopNumber !== 'notAvailable') {
                    product.strekkodeBase64Url = generateBarcode(product.coopNumber);
                }
                break;
            case IdentifierType.EPD_NR:
                // skip dummy product
                if (product.epdNr !== '-1') {
                    product.strekkodeBase64Url = generateBarcode(product.epdNr);
                }
                break;
        }
    }
    return product;
}

const getProductsWithBarcode = (productData: ProductData): ProductItem[] => {
    return productData.products.map(p => generateBarcodeIfMissing(p, productData.identiferType));
}

const ProductCard = (props: Props) => {

    const [products, setProducts] = useState<ProductItem[]>(getProductsWithBarcode(props.productData));

    useEffect(() => {
        setProducts(getProductsWithBarcode(props.productData));
    }, [props.productData]);
            
    const findIdentifierValue = (product: ProductItem, identiferType: IdentifierType) => {
        if (identiferType === IdentifierType.GTIN) {
            return product.orderableUnitGtin;
        }
        if (identiferType === IdentifierType.COOP_NR) {
            return product.coopNumber !== 'notAvailable' ? product.coopNumber : 'Har ikke Coop nr';
        }
        return product.epdNr;
    }
    const topProducts = props.topItems ? products.slice(0, props.topItems) : products; // Get the top items
    return (        
        <div>
            <Row>               
                {topProducts.filter(x => x.epdNr !== '-1').map((row, index) => (            
                    <Col xs={6} md={6} key={index} className="mb-1">
                        <Card className="mt-1">
                            <Card.Body style={{ padding: '8px' }}>                               
                                <Row>
                                    <Col xs={2} className="text-center">
                                        <ThumbnailProductImage                                            
                                            heightInPx={80}
                                            widthInPx={80}
                                            gtin={
                                                props.productData &&
                                                props.productData.products.length > 0
                                                ? props.productData.products[index]?.imageGtin
                                                : null
                                            }                                            
                                            />
                                    </Col>
                                    <Col>
                                        <Row className="text-center">
                                            <Col>{row.produktnavn} </Col>                                                               
                                        </Row> 
                                        <Row className="text-center">
                                            <Col>{row.totalBaseUnitInOrderableUnit} stk - {findIdentifierValue(row, props.productData.identiferType)} ({props.productData.identiferType})</Col>                                                                 
                                        </Row>
                                        <Row className="text-center">
                                            <Col>{row.strekkodeBase64Url && 
                                            <img 
                                            src={row.strekkodeBase64Url} 
                                            alt="barcode" />}</Col>                                                               
                                        </Row> 
                                    </Col>                                    
                                </Row> 
                            </Card.Body>            
                        </Card>
                    </Col>
                    ))}                 
            </Row>
        </div>        
        );
}

export default ProductCard;