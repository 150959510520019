import { Sketch } from "@uiw/react-color";
import { useEffect, useState } from "react";


interface AdvancedColorPickerProps {
	onColorChange: (color: string) => void;
	currentColor?: string;

}

const AdvancedColorPicker = ({ onColorChange, currentColor }: AdvancedColorPickerProps) => {
	const [selectedColor, setSelectedColor] = useState<string>(currentColor || '#3a737d');

	useEffect(() => {
		setSelectedColor(currentColor || '#3a737d'); // Update when the prop changes
	}, [currentColor]);

	// Handler for color change from the Sketch color picker
	const handleColorChange = (color: { hex: string }) => {
		setSelectedColor(color.hex);
		onColorChange(color.hex);
	};

	return (
		<div style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px', width: '300px' }}>
			{/* Sketch Color Picker */}
			<Sketch color={selectedColor} onChange={handleColorChange} />

			{/* Display Selected Color and Hex Code */}
			<div style={{ marginTop: '15px', display: 'flex', alignItems: 'center' }}>
				<div
					style={{
						width: '30px',
						height: '30px',
						borderRadius: '4px',
						backgroundColor: selectedColor,
						border: '1px solid #ccc',
						marginRight: '10px',
					}}
				></div>
				<span style={{ fontSize: '14px', color: '#333' }}>Hex: {selectedColor}</span>
			</div>
		</div>
	)

}

export default AdvancedColorPicker;