import { useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import MediastoreMediaApi from "../../../../Services/MediastoreMediaApi";
import { IcebergColors, IcebergIcon } from "@tradesolution/iceberg-ui-react";

interface Props {
  widthInPx: number;
  heightInPx: number;
  gtin: string | null | undefined;
  mediaID?: number | null | undefined;
  showPlaceholder?: boolean;
}

interface ImageContainer {
  blob: Blob | null;
  width: number;
  height: number;

  x: number;
  y: number;

  isHovered?: boolean;
}

interface ImageSize {
  width: number;
  height: number;
}

const ProductImage = (props: Props) => {
  const placeHolderImgUrl = props.showPlaceholder
    ? `https://placehold.co/${props.widthInPx.toString()}x${props.heightInPx.toString()}`
    : "";

  const [imageContainer, setImageContainer] = useState<ImageContainer>({
    blob: null,
    width: props.widthInPx,
    height: props.heightInPx,
    x: 0,
    y: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const getRndContainerSize = (imageContainerSize: ImageSize, actualImageSize: ImageSize): ImageSize => {
    const imageRatio = actualImageSize.width / actualImageSize.height;
    const containerRatio = imageContainerSize.width / imageContainerSize.height;

    if (imageRatio > containerRatio) {
      return {
        width: imageContainerSize.width,
        height: imageContainerSize.width / imageRatio,
      };
    } else {
      return {
        width: imageContainerSize.height * imageRatio,
        height: imageContainerSize.height,
      };
    }
  };

  const loadPackageFromMediastore = async (gtin: string) => {    
    let response = await MediastoreMediaApi.packages.byGtin(gtin); 
    return response;
  };

  const loadImageFromMediastore = async (gtin: string) => {
    setIsLoading(true);
    try {
      const imagePackage = await loadPackageFromMediastore(gtin);
      const response = imagePackage.productImages.find((img) => img.isMainImage);
      if (response) {
        // we need the actual image dimensions to create a proper Rnd container
        if(!response.previewPngBlobId)
        {
          setError("Mangler bilde");          
        }
        let blobResponse = await MediastoreMediaApi.imageBlobPng.get(response.previewPngBlobId);        
        const imageBitmap = await createImageBitmap(blobResponse);
        const imageContainerSize = {
          width: props.widthInPx,
          height: props.heightInPx,
        };
        const actualImageSize = {
          width: imageBitmap.width,
          height: imageBitmap.height,
        }
        const rndContainerSize = getRndContainerSize(imageContainerSize, actualImageSize);        
        setImageContainer({ ...imageContainer, blob: blobResponse, width: rndContainerSize.width, height: rndContainerSize.height, x: (imageContainerSize.width - rndContainerSize.width) / 2, y: (imageContainerSize.height - rndContainerSize.height) / 2 });
      }
    } catch (error: any) {
      console.error("Error fetching image from MediastoreApi");
      setError("Mangler bilde");
    }
    setIsLoading(false);
  };

  const loadImageAngleFromMediastore = async (gtin: string, mediaID: number) => {
    setIsLoading(true);
    try {
      const imagePackage = await loadPackageFromMediastore(gtin);
      const response = imagePackage.productImages.find((img) => img.medieID === mediaID);
      if (response) {
        if(!response.previewPngBlobId)
          {
            setError("Kunne ikke laste bilde!");          
          }
        // we need the actual image dimensions to create a proper Rnd container
        let blobResponse = await MediastoreMediaApi.imageBlobPng.get(response.previewPngBlobId);
        const imageBitmap = await createImageBitmap(blobResponse);
        const imageContainerSize = {
          width: props.widthInPx,
          height: props.heightInPx,
        };
        const actualImageSize = {
          width: imageBitmap.width,
          height: imageBitmap.height,
        }
        const rndContainerSize = getRndContainerSize(imageContainerSize, actualImageSize);        
        setImageContainer({ ...imageContainer, blob: blobResponse, width: rndContainerSize.width, height: rndContainerSize.height, x: (imageContainerSize.width - rndContainerSize.width) / 2, y: (imageContainerSize.height - rndContainerSize.height) / 2 });
      }
    } catch (error: any) {
      console.error("Error fetching image from MediastoreApi");
      setError("Kunne ikke laste bilde!");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setError(undefined);
    setIsLoading(false);

    if (props.gtin && props.mediaID) {
      loadImageAngleFromMediastore(props.gtin, props.mediaID);
    } else if (props.gtin) {
      loadImageFromMediastore(props.gtin);
    } else {
      setImageContainer({ ...imageContainer, blob: null });
    }
  }, [props.gtin, props.mediaID]);
  
  if(!imageContainer?.blob && !props.showPlaceholder){
    return null;
  }
  
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        width: props.widthInPx + "px",
        height: props.heightInPx + "px",
      }}
    >
      <Rnd
        className="movable-element"
        size={{ width: imageContainer.width, height: imageContainer.height }}
        position={{ x: imageContainer.x, y: imageContainer.y }}
        lockAspectRatio={true}
        bounds={".template-container"}
        onDragStop={(e, d) => {
          setImageContainer({ ...imageContainer, x: d.x, y: d.y });
        }}
        onResize={(e, direction, ref, delta, position) => {
          setImageContainer({
            ...imageContainer,
            width: ref.offsetWidth,
            height: ref.offsetHeight,
            x: position.x,
            y: position.y,
          });
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: imageContainer.width,
            maxHeight: imageContainer.height,
          }}
        >
          {error && <div><IcebergIcon icon="no-photo" size="2x" color={IcebergColors.GY200}/></div>}
          {isLoading && !error && <div>Henter bilde...</div>}
          {!isLoading && !error && (
            <img
              draggable={false}
              className="product-image"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              src={
                 props.showPlaceholder ? 
                  placeHolderImgUrl :
                  imageContainer?.blob ?                   
                  URL.createObjectURL(imageContainer?.blob) :
                  ""
              }
            />
          )}
        </div>
      </Rnd>
    </div>
  );
};

export default ProductImage;
