import {
	useEffect, useState,
} from 'react';
import {
	NavDropdown,
} from 'react-bootstrap';
import './index.scss';
import MediastoreMediaApi from '../../Services/MediastoreMediaApi';
import { Company } from '../../Services/MediastoreMediaApi/models';
import { CustomerGlnSwitcher, ICustomer } from './CustomerGlnSwitcher';
import { useSelectedCompanyContext } from '../../Shared/SelectedCompanyContext';
import useMediastoreUser from '../../Shared/useMediaStoreUser';

export interface Props {

};

const CustomerGlnPicker = (props: Props) => {	
	const { selectedCompany, setSelectedCompany } = useSelectedCompanyContext();
	const mediaStoreUser = useMediastoreUser(selectedCompany?.tsCustomerId);
    const [companies, setCompanies] = useState<Company[]>([]);

    const loadAllCompanies = async () => {
        const response = await MediastoreMediaApi.companies.get();		
        setCompanies(response);
    };

    useEffect(() => {
		if (mediaStoreUser && mediaStoreUser.accessToCompanies && mediaStoreUser.accessToCompanies.length > 0) {
        	setCompanies(mediaStoreUser.accessToCompanies);
			if (!selectedCompany?.tsCustomerId) {
				setSelectedCompany(mediaStoreUser.accessToCompanies[0]);
			}
		}
    }, [mediaStoreUser, selectedCompany]);

	useEffect(() => {
		if(selectedCompany && selectedCompany.tsCustomerId) {
			setSelectedCompanyInMediastoreMediaApi(selectedCompany);
		}				
    }, []);

	const setSelectedCompanyInMediastoreMediaApi = async (selected: Company) => {
		await MediastoreMediaApi.mediastoreUser.changeActiveCompany(selected.tsCustomerId, selected.gln.toString());
	}

	const handleSelectedCompanyChange = (selected?: ICustomer) => {
		if (selected && companies && companies.length > 0) {
			const item = companies.find((c) => c.tsCustomerId === selected?.tsKundeId && c.gln.toString() === selected?.differentiator);
			if (item) {
				setSelectedCompany(item);
				setSelectedCompanyInMediastoreMediaApi(item);
			}
		}
	};

	const handleTsKundeQueryChange = (query: string) => {
		if (mediaStoreUser?.isTradesolutionAnsatt) {
			loadAllCompanies();
		}
	};

	return (
		<NavDropdown
			id="customer-switcher-dropdown"
			title={selectedCompany?.name ?? 'Velg kunde'}
			className="customDropdownSupplierSwitcher"
			align="end"
		>
			<div className="supplierSwitcherContainer">
				<CustomerGlnSwitcher
					options={companies.map((c) => ({ tsKundeId: c.tsCustomerId, tsKundeNavn: c.name, differentiator: c.gln.toString() }))}
					selectedCustomerTsKundeId={selectedCompany?.tsCustomerId}
					isTradesolutionUser={mediaStoreUser?.isTradesolutionAnsatt ?? false}
					onSelectedChange={(selected: ICustomer) => handleSelectedCompanyChange(selected)}
					onQueryChange={(q: string) => handleTsKundeQueryChange(q)}
				/>
			</div>
		</NavDropdown>
	);
};

export default CustomerGlnPicker;