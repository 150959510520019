import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MediastoreMediaApi from '../Services/MediastoreMediaApi';
import { MediastoreUser } from '../Services/MediastoreMediaApi/models';

const useMediaStoreUser = (selectedTsKundeId: string) => {   
    const [mediastoreUser, setMediastoreUser] = useState<MediastoreUser>();
    const navigate = useNavigate();
    
    const loadMediastoreUser = async () => {
        try {
            const response = await MediastoreMediaApi.mediastoreUser.get();
            setMediastoreUser(response);
        } catch (error: any) {
            if (error?.response && error.response?.status === 403) {
                navigate('/unauthorized');
            }
        }
    };

    useEffect(() => {
        loadMediastoreUser();
    }, [selectedTsKundeId]);

    return mediastoreUser;
};

export default useMediaStoreUser;