import React, { createContext, useContext, ReactNode } from 'react';
import { Company, MediastoreUser } from '../Services/MediastoreMediaApi/models';
import { useSelectedCompany } from './useSelectedCompany';

interface SelectedCompanyContextType {
    selectedCompany: Company;
    setSelectedCompany: (value: Company) => void;
}

const SelectedCompanyContext = createContext<SelectedCompanyContextType | undefined>(undefined);

export const SelectedCompanyProvider = ({ children }: { children: ReactNode }) => {
    const { selectedCompany, setSelectedCompany } = useSelectedCompany();

    return (
        <SelectedCompanyContext.Provider value={{ selectedCompany, setSelectedCompany }}>
            {children}
        </SelectedCompanyContext.Provider>
    );
};

export const useSelectedCompanyContext = () => {
    const context = useContext(SelectedCompanyContext);
    if (!context) {
        throw new Error('useSelectedCompanyContext must be used within a SelectedCompanyProvider');
    }
    return context;
};