import { useEffect, useState } from "react";
import { generateBarcode } from "../../../../Shared/BarcodeGenerator";
import { IdentifierType, ProductData, ProductItem } from "../../ProductItem";
import { Card, Col, Row } from "react-bootstrap";
import ProductImage from "../components/ProductImage";
import ThumbnailProductImage from "../components/ThumbnailProductImage";

interface Props {
    productData: ProductData;
    topItems?: number; // Specify the number of top items to return
}

const generateBarcodeIfMissing = (product: ProductItem, identiferType: IdentifierType): ProductItem => {
    if (!product.strekkodeBase64Url) {
        switch (identiferType) {
            case IdentifierType.GTIN:
                product.strekkodeBase64Url = generateBarcode(product.orderableUnitGtin);
                break;
            case IdentifierType.COOP_NR:
                if (product.coopNumber !== 'notAvailable') {
                    product.strekkodeBase64Url = generateBarcode(product.coopNumber);
                }
                break;
            case IdentifierType.EPD_NR:
                // skip dummy product
                if (product.epdNr !== '-1') {
                    product.strekkodeBase64Url = generateBarcode(product.epdNr);
                }
                break;
        }
    }
    return product;
}

const getProductsWithBarcode = (productData: ProductData): ProductItem[] => {
    return productData.products.map(p => generateBarcodeIfMissing(p, productData.identiferType));
}

const ProductCard = (props: Props) => {

    const [products, setProducts] = useState<ProductItem[]>(getProductsWithBarcode(props.productData));

    useEffect(() => {
        setProducts(getProductsWithBarcode(props.productData));
    }, [props.productData]);
            
    const findIdentifierValue = (product: ProductItem, identiferType: IdentifierType) => {
        if (identiferType === IdentifierType.GTIN) {
            return product.orderableUnitGtin;
        }
        if (identiferType === IdentifierType.COOP_NR) {
            return product.coopNumber !== 'notAvailable' ? product.coopNumber : 'Har ikke Coop nr';
        }
        return product.epdNr;
    }
    const topProducts = props.topItems ? products.slice(0, props.topItems) : products; // Get the top items
    return (        
        <div>
            {topProducts.filter(x => x.epdNr !== '-1').map((row, index) => (            
            <Card className="mt-1">
                <Card.Body style={{ padding: '8px' }}>
                    <Row>
                        <Col xs={1}>
                            <ThumbnailProductImage                                
                                heightInPx={50}
                                widthInPx={50}
                                gtin={
                                    props.productData &&
                                    props.productData.products.length > 0
                                    ? props.productData.products[index]?.imageGtin
                                    : null
                                }                                
                                />
                        </Col>
                        <Col className="text-start">
                            <Row >
                                <Col>{row.produktnavn} </Col>                                                               
                            </Row> 
                            <Row >
                                <Col>{findIdentifierValue(row, props.productData.identiferType)} ({props.productData.identiferType})</Col>                                                                 
                            </Row>
                        </Col>
                        <Col xs={2} className="text-center align-self-center">
                        {row.totalBaseUnitInOrderableUnit} stk
                        </Col>
                        <Col className="text-end">                           
                            {row.strekkodeBase64Url && <img src={row.strekkodeBase64Url} alt="barcode" />}                                                         
                        </Col>
                    </Row> 
                </Card.Body>            
            </Card>
        ))}        
        </div>        
        );
}

export default ProductCard;