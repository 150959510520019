import { useEffect, useState } from "react";
import { Rnd } from "react-rnd";

interface Size {
	width: number;
	height: number;
}

interface Position {
    x: number;  
    y: number;
}

interface Props {
	children: React.ReactNode;
	size: Size;
    top?: number;
    lockAspectRatio?: boolean;
    position?: Position;
}

const MovableElement = (props: Props) => {

    const [size, setSize] = useState<Size>(props.size);
    const [position, setPosition] = useState({ x: 0 - (props.size.width / 2), y: props.top ?? 0 }); 

    useEffect(() => {
        // reset position when size changes through props
        setSize(props.size);
        setPosition({ x: 0 - (props.size.width / 2), y: props.top ?? 0 });
    }, [props.size]);

    useEffect(() => {
        if (props.position)
            setPosition(props.position);        
    }, [props.position]);

	return (
		<Rnd
            className="movable-element"
            size={size}
            position={position}
            lockAspectRatio={props.lockAspectRatio}
            onDragStop={(e, d) => {
                setPosition({ x: d.x, y: d.y });
            }}
            onResize={(e, direction, ref, delta, position) => {
                setSize({
                    width: ref.offsetWidth,
                    height: ref.offsetHeight,
                });
            }}
			bounds={".template-container"}
		>
			{props.children}
		</Rnd>
	);
};

export default MovableElement;
