import { useEffect, useRef, useState } from 'react';
import { Col, FormControl, Row, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import PicassoApi, { LogoDto } from '../../../../Services/PicassoApi';
import { IcebergButton, IconButton } from '@tradesolution/iceberg-ui-react';
import FileUploader from '../FileUploader';
import ConfirmButton from '../../../../components/ConfirmButton';
import LogRocket from 'logrocket';
import { useSelectedCompanyContext } from '../../../../Shared/SelectedCompanyContext';

interface Props {
	onSave: (logoFileName?: string) => void;
	selectedLogoFileName?: string
}

function LogoModal(props: Props) {

	const { selectedCompany } = useSelectedCompanyContext();
	const [show, setShow] = useState(false);
	const initialRender = useRef(true);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [logos, setLogos] = useState<LogoDto[]>([]);
	const [logoUrls, setLogoUrls] = useState<{ [key: string]: string }>({});
	const [selectedLogo, setSelectedLogo] = useState<LogoDto>();

	//søkefelt for logoer
	const [searchQuery, setSearchQuery] = useState<string>('');

	const filteredLogos = logos?.filter(logo =>
		logo.fileName.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const getLogos = async () => {
		if (!selectedCompany?.tsCustomerId) {
			return;
		}
		const logosResponse = (await PicassoApi.logos.get(selectedCompany.tsCustomerId)) ?? [];
		setLogos(logosResponse);
		return logosResponse;
	};

	const getLogo = async (fileName: string) => {
		if (!selectedCompany?.tsCustomerId) {
			return;
		}
		const logoBlob = await PicassoApi.logos.getLogo(fileName, selectedCompany.tsCustomerId);
		if (logoBlob) {
			const logoUrl = URL.createObjectURL(logoBlob);
			setLogoUrls(prevState => ({ ...prevState, [fileName]: logoUrl }));
		}
	};

	useEffect(() => {
		getLogos();
	}, []);

	useEffect(() => {
		logos.forEach(logo => {
			getLogo(logo.fileName);
			if (props.selectedLogoFileName === logo.fileName) {
				setSelectedLogo(logo);
			}
		});
	}, [logos]);

	useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false;
			return;
		}
		setSelectedLogo(undefined);
		getLogos();
		localStorage.removeItem('selectedLogoFileName');
	}, [selectedCompany]);

	const handleSavedLogo = () => {
		if (selectedLogo) {
			props.onSave(selectedLogo.fileName);
			localStorage.setItem('selectedLogoFileName', selectedLogo.fileName);
		}
		else {
			props.onSave(undefined);
			localStorage.removeItem('selectedLogoFileName');
		}
		setShow(false);
	}

	const toggleSelection = (logo: LogoDto) => {
		if (selectedLogo?.fileName === logo.fileName) {
			setSelectedLogo(undefined);
			return;
		}
		setSelectedLogo(logo);
	}

	const deleteLogo = async (logo: LogoDto) => {
		await PicassoApi.logos.delete(logo.fileName, selectedCompany?.tsCustomerId);
		await getLogos();
		LogRocket.track('Delete logo', {
			tsKundeNavn: selectedCompany.name,
			fileName: logo.fileName
		});
	};

	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);

	const handleLogoUpload = async (file: File): Promise<void> => {
		setIsUploading(true);
		setIsImageUploaded(false);
		await PicassoApi.logos.post(file, selectedCompany?.tsCustomerId);
		setIsImageUploaded(true);
		const logosLocal = (await getLogos()) ?? [];
		setIsUploading(false);
		LogRocket.track('Upload logo', {
			tsKundeNavn: selectedCompany.name,
			fileName: file.name
		});

		// Set the uploaded logo as the default selected logo
		const uploadedLogo = logosLocal.find(logo => logo.fileName === file.name);
		if (uploadedLogo) {
			setSelectedLogo(uploadedLogo);
		}
	};

	return (
		<>
			<div className="d-grid">
				<IcebergButton variant="outline-primary" icon='edit' onClick={handleShow}>
					Velg logo
				</IcebergButton>
			</div>

			<Modal show={show} onHide={handleClose} size='xl'>
				<Modal.Header closeButton>
					<Modal.Title>Velg logo</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="row mb-4">
						<Col className='col-8'>
							<p>Her kan du laste opp logoer og velge den du ønsker. Bruk unike navn for å unngå overskriving</p>
						</Col>
						<Col className='text-end'>
							<FileUploader
								handleFile={(file: File) => handleLogoUpload(file)}
								isImageUploaded={isImageUploaded}
								uploading={isUploading}
								title={'Last opp logo'}
							/>
						</Col>
					</Row>
					<Row className="row mb-4">
						<Col>
							<FormControl
								type="text"
								placeholder="Søk med filnavn"
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</Col>
					</Row>
					<Table>
						<thead>
							<tr>
								<th className='text-center'>Bilde</th>
								<th>Filnavn</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{filteredLogos.map((logo, index) => (
								<tr key={index}>
									<td className='text-center'>
										<img height={30} src={logoUrls[logo.fileName]} alt={logo.fileName} />
									</td>
									<td>{logo.fileName}</td>
									<td className='text-end'>
										<div className="d-flex justify-content-end">
											<IcebergButton
												variant={selectedLogo?.fileName === logo.fileName ? 'outline-secondary' : 'outline-primary'}
												icon={selectedLogo?.fileName === logo.fileName ? 'check' : 'plus'}
												onClick={() => toggleSelection(logo)}
												className="me-2"
											>
												{selectedLogo?.fileName === logo.fileName ? 'Valgt' : 'Velg'}
											</IcebergButton>
											<ConfirmButton
												variant='outline-danger'
												onClick={() => deleteLogo(logo)}
											>
											</ConfirmButton>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</Table>

				</Modal.Body>
				<Modal.Footer>
					<IconButton icon='close' variant="outline-primary" className="col" onClick={handleClose}>
						Lukk
					</IconButton>


					<IconButton icon="disk" variant="primary" className="col" onClick={handleSavedLogo}>
						Lagre
					</IconButton>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default LogoModal;