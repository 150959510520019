import { useState } from 'react';
import './index.scss';
import { Button, Card, Carousel, Col, Container, Row, Image, Nav } from 'react-bootstrap';
import CustomDesignSection from '../Designer/Components/RightCol/CustomDesignSection';
import TaKontaktSection from './TaKontaktSection';
import { Link } from 'react-router-dom';

const LandingPage = () => {
	const [showModal, setShowModal] = useState(false);

	{/* Carousel Images */ }
	const images = [
		{ src: "/img/Slider1.png", alt: "" },
		{ src: "/img/Slider2.png", alt: "Slide 2" },
		{ src: "/img/Slider3.png", alt: "Slide 3" },
	];

	const cards = [
		{ id: 1, src: "/img/TemplatePreview1.png", title: "Card 1" },
		{ id: 2, src: "/img/TemplatePreview2.png", title: "Card 2" },
		{ id: 3, src: "/img/TemplatePreview3.png", title: "Card 3" },
		{ id: 4, src: "/img/TemplatePreview4.png", title: "Card 4" },
		{ id: 5, src: "/img/TemplatePreview5.png", title: "Card 5" },
	]

	return (

		<div className="container">


			{/* Header Carousel */}
			<Carousel>
				{images.map((image, index) => (
					<Carousel.Item key={index}>
						<img
							className="d-block w-100"
							src={image.src}
							alt={image.alt}
						/>
						<Carousel.Caption>
							{/** 
							<h3>{` ${index + 1}`}</h3>
							<p>{`Description for slide ${index + 1}`}</p>*/}
						</Carousel.Caption>
					</Carousel.Item>
				))}
			</Carousel>

			{/* New Text and Image Section */}
			<Container className="mt-4">
				<div className="text-front mt-5 mb-5">
					<h2>Gratulerer! Du har nå tilgang på bransjens beste løsning for produksjon av innsalgsark. </h2>
					<p>
						Innsalgsark er en unik mulighet til å effektivisere kundemøtene. Trenger du hjelp med å tilpasse til grafisk profil eller å optimalisere så er ikke hjelpen langt unna!
					</p>
				</div>

				{/* First Row */}
				<Row>
					<Col md={6} className="pe-3">
						<Image src="/img/GrafiskeElementerPic.png" fluid={true} alt="Box 1" />
					</Col>


					<Col md={6}>
						<h2>Grafiske elementer</h2>
						<p>
							I Picasso gjør du det meste selv. Enkelt og greit.

							Noen ganger kan du kanskje ha behov for å løfte innsalgsarket ditt det lille ekstra, enten med en prisbombe eller en sesongtilpasset bakgrunn for å sikre innsalg av nettopp ditt produkt.
						</p>
						<Col className="mt-3">
							<a href="/designer" className="btn btn-outline-primary me-3">
								Start med å lage ditt første innsalgsark
							</a>
							<Button variant="outline-primary" onClick={() => setShowModal(true)}>
								Ta kontakt
							</Button>
						</Col>
					</Col>

				</Row>

				<Row className="mt-4 mb-5">
					<Col md={8} lg={6}>
						<TaKontaktSection name="" email="" phone="" showModal={showModal} onModalClosed={() => setShowModal(false)} />
					</Col>
				</Row>

				{/* Second Row */}
				<Row className="mt-4 mb-5">
					<Col md={6}>

						<h2>Realistiske butikkskisser</h2>
						<h3>Hvordan vil kampanjen se ut i butikk?	</h3>

						<p>
							I innsalget er det viktig å vise hvordan kampanjen er tenkt utført og hvilke elementer som følger med enten det er POS-materiell eller SoMe-poster. Spirit hjelper deg med utvikling av dette både effektivt POS-materiell, engasjerende SoM-eposter og ikke minst kjedetilpassede skisser som viser hvordan du ønsker å aktivisere shopper i butikk. Vi tilpasser til kjede og kategori for å sikre gjennomføring av aktiviteten.
						</p>
					</Col>
					<Col md={6}>
						<Image src="/img/MakeItPop.png" fluid alt="Box 4" style={{ maxHeight: "600px" }} />
					</Col>
				</Row>

				{/* Ta kontakt Section 
				<Row className="mt-4 mb-5 justify-content-center">
					<Col md={8} lg={6}>
						<TaKontaktSection name="" email="" phone="" />
					</Col>
				</Row>*/}
			</Container >
		</div >
	);


}
export default LandingPage;