import { useEffect, useState } from "react";
import PicassoApi from "../../../../Services/PicassoApi";
import { useSelectedCompanyContext } from "../../../../Shared/SelectedCompanyContext";
import MovableElement from "../../../../components/MovableElement";
import { AutoTextSize } from "auto-text-size";

interface Props {
  logoFileName: string | undefined;
  logoText: string | undefined;
  fontFamily: string;
  fontColor: string;
  fontSize: number;
  templateId: number | undefined;
  position?: { x: number; y: number };
}

const Logo = (props: Props) => {
  const { selectedCompany } = useSelectedCompanyContext();
  const [logoBlob, setLogoBlob] = useState<Blob | null>(null);

  const getLogo = async (fileName: string) => {
    const logoBlob = await PicassoApi.logos.getLogo(
      fileName,
      selectedCompany?.tsCustomerId
    );
    if (logoBlob) {
      setLogoBlob(logoBlob);
    }
  };

  useEffect(() => {
    if (props.logoFileName) {
      getLogo(props.logoFileName);
    } else {
      setLogoBlob(null);
    }
  }, [props.logoFileName]);

  const [logoSize, setLogoSize] = useState({ width: 200, height: 100 });
  const [logoTextSize, setLogoTextSize] = useState({ width: 300, height: 50 });

  // reset sizes and positions when template changes
  useEffect(() => {
    setLogoTextSize({ width: 300, height: 50 });
    setLogoSize({ width: 200, height: 100 });
  }, [props.templateId]);

  
    return (
      <div>
        {logoBlob && (
          <MovableElement 
            size={logoSize} 
            lockAspectRatio={true} 
            position={props.position}>
            <img
              id="logo"
              draggable="false"
              src={URL.createObjectURL(logoBlob)}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </MovableElement>
        )}
        {!logoBlob && (
          <MovableElement 
            size={logoTextSize}
            position={props.position}>
            <AutoTextSize
              mode="box"
              minFontSizePx={10}
              maxFontSizePx={100}
              fontSizePrecisionPx={0.1}
              style={{
                fontFamily: props.fontFamily,
                color: props.fontColor ?? "#000",
              }}
            >
              <div contentEditable>{props.logoText}</div>
            </AutoTextSize>
          </MovableElement>
        )}
      </div>      
    );
};
export default Logo;
