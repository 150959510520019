import { IcebergIcon } from "@tradesolution/iceberg-ui-react";
import { Accordion } from "react-bootstrap";
import ProductAngleSelector from "../ProductAngleSelector";
import { Template } from "..";

interface Props {
    gtins: string[];
    template: Template;
    onAngleSelected: (gtin: string, angle: string, mediaID: number) => void;
}

const ProductImageSelector = (props: Props) => {

    // take max template.imageCount from gtins
    const maxImageCount = props.template.imageCount;

    const gtins = props.gtins.slice(0, maxImageCount);

    const handleAngleSelected = (gtin: string, angle: string, mediaID: number): void => {
        props.onAngleSelected(gtin, angle, mediaID);
    }

    if (gtins.length === 0) {
        return null;
    }

    return (
        <Accordion defaultActiveKey="0" className="mt-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <IcebergIcon icon="image" className="me-2" /> Valgte bilder
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        {gtins.map((gtin, index) => (
                            <ProductAngleSelector key={gtin} gtin={gtin} onAngleSelected={(angle: string, mediaID: number) => handleAngleSelected(gtin, angle, mediaID)} template={props.template} produktIndex={index} />
                        ))}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default ProductImageSelector;
