import { Col, Row, Offcanvas, Alert } from 'react-bootstrap';
import './index.scss';
import { IcebergButton } from '@tradesolution/iceberg-ui-react';
import RightCol from './Components/RightCol';
import { useEffect, useState } from 'react';
import LeftCol, { Template, Templates } from './Components/LeftCol';
import { ContactInfo, DesignData, TemplateData } from './TemplateData';
import { useMsal } from '@azure/msal-react';
import { useSelectedCompanyContext } from '../../Shared/SelectedCompanyContext';
import MyBrandExportModal from './Components/MyBrandExportModal';
import PicassoApi from '../../Services/PicassoApi';
import useMediastoreUser from '../../Shared/useMediaStoreUser';
import ImageTableTemplate from './templates/ImageTableTemplate';
import { IdentifierType, ProductData } from './ProductItem';
import CardTemplate from './templates/CardTemplate';
import TwoColumnCardTemplate from './templates/TwoColumnCardTemplate';
import HorizontalCardTemplate from './templates/HorizontalCardTemplate';
import PdfExportModal from './Components/PdfExportModal';

const Designer = () => {

	const { accounts } = useMsal();
	const userName = accounts[0] && accounts[0].name;
	const email = accounts[0] && accounts[0].username;
    const [hasMyBrandAccess, setHasMyBrandAccess] = useState<boolean>(true);
	const [hasPicassoAccess, setHasPicassoAccess] = useState<boolean>(true);

	const { selectedCompany } = useSelectedCompanyContext();
	const mediastoreUser = useMediastoreUser(selectedCompany?.tsCustomerId);

	const defaultTemplateData: TemplateData = {
		designData: {
			title: 'Eksempel tittel',
		},
		productData: {
			identiferType: IdentifierType.EPD_NR,
			products: []
		},
		contactInfo: {
			email: localStorage.getItem('email') ?? email,
			name: localStorage.getItem('userName') ?? userName ?? '',
			phone: localStorage.getItem('phoneNumber') || '',
		},
		showDemoText: (!hasMyBrandAccess && !hasPicassoAccess) ?? false
	};

	const getPicassoAccess = async () => {
		let hasPicassoAccess = await PicassoApi.hasAccessToPicasso.get(selectedCompany?.tsCustomerId ?? null);
		setHasPicassoAccess(hasPicassoAccess);		
	};

	useEffect(() => {		
		if(mediastoreUser){
		  let hasAccess = mediastoreUser?.privateStoreSubscription?.isPaidSubscription ?? false;
		  setHasMyBrandAccess(hasAccess);
		}
	  }, [mediastoreUser]);

	useEffect(() => {
		if(!hasMyBrandAccess && !hasPicassoAccess)		
			setTemplateData({ ...templateData, showDemoText: true });
		else
			setTemplateData({ ...templateData, showDemoText: false }); 
		
	}, [hasMyBrandAccess, hasPicassoAccess]);

	const [templateData, setTemplateData] = useState<TemplateData>(defaultTemplateData);

	// reset product data and logo image when selectedTsKunde changes
	useEffect(() => {		
		getPicassoAccess();
		setTemplateData(defaultTemplateData);
	}, [selectedCompany]);

	const handleDesignDataChanged = (data: DesignData): void => {
		setTemplateData({ ...templateData, designData: data });
	}

	const [selectedTemplate, setSelectedTemplate] = useState<Template>(Templates.find(o => o.id === 1) as Template);

	//mobile-version

	const [showLeftDrawer, setShowLeftDrawer] = useState(false);
	const [showRightDrawer, setShowRightDrawer] = useState(false);

	const handleTemplateSelected = (o: Template): void => {
		setSelectedTemplate(o);
	}
	
	const handleProductDataChanged = (data: ProductData): void => {		
		setTemplateData({ ...templateData, productData: data });
	}

	const handleContactInfoChanged = (contactInfo: ContactInfo): void => {
		setTemplateData({ ...templateData, contactInfo: contactInfo });
	}

	const handleAngleSelected = (gtin: string, angle: string, mediaID: number): void => {

		// update the angle for the product with the given gtin
		const products = templateData.productData.products.map((product) => {
			if (product.imageGtin === gtin) {
				product.angle = angle;
				product.mediaID = mediaID;
			}
			return product;
		});

		const updatedProductData = { ...templateData.productData, products: products };
		setTemplateData({ ...templateData, productData: updatedProductData });
	}

	return (
		<div>
			<Row className='mt-3 mb-3'>
				<Col>
					<h3>Designer</h3>
				</Col>
				{(!hasMyBrandAccess && !hasPicassoAccess) && (
					<Col xs={2} lg={5}>
						<Alert variant="warning">
						<span>Du må gjerne prøve Picasso men for å fjerne "Demo" så må du ha tilgang til Picasso eller MyBrand. <a href="https://tradesolution.no/tjenester/mybrandmedia-bildebank/" target="_blank">Få tilgang her</a></span> 
						</Alert>
					</Col>
				)}				
				<Col className='text-end'>					
					<PdfExportModal 
						title={templateData.designData.title}
						templateData={templateData}
						templateId={selectedTemplate.id}
						orientation={selectedTemplate.orientation}/>
					<MyBrandExportModal 
						title={templateData.designData.title}
						templateData={templateData}
						templateId={selectedTemplate.id}/>
				</Col>
			</Row>
			{/* Main content*/}
			<Row>
				{/*LeftCol for Desktop */}
				<Col md={2} className='d-none d-xxl-block'>
					<LeftCol
						onTemplateSelected={handleTemplateSelected}
						onProductDataChanged={handleProductDataChanged}
						productData={templateData.productData}
						onAngleSelected={handleAngleSelected}
					/>
				</Col>
				{/* Center Template */}
				<Col className='template-col' align="center">					
					{selectedTemplate && selectedTemplate.id === 12 && <CardTemplate selectedTemplate={selectedTemplate} templateData={templateData} />}
					{selectedTemplate && selectedTemplate.id === 13 && <TwoColumnCardTemplate selectedTemplate={selectedTemplate} templateData={templateData} />}
					{selectedTemplate && selectedTemplate.id === 14 && <HorizontalCardTemplate selectedTemplate={selectedTemplate} templateData={templateData} />}
					{selectedTemplate && selectedTemplate.id < 12 && <ImageTableTemplate selectedTemplate={selectedTemplate} templateData={templateData} />}
					{/* {templateData && <ImageTableTemplate templateId={selectedTemplate?.id} templateData={templateData} />} */}					
				</Col>
				{/* RightCol for Desktop */}
				<Col md={2} className='d-none d-xxl-block'>
					<RightCol 
					onDesignDataChanged={handleDesignDataChanged} 
					contactInfo={templateData.contactInfo} 
					onContactInfoChanged={handleContactInfoChanged} />
				</Col>
			</Row>
			{/* Mobile Buttons */}
			<div className="d-xxl-none fixed-bottom d-flex justify-content-around gap-2 bg-white p-3">
				{/* Button to open Left Drawer */}
				<IcebergButton icon="document" className='w-50' variant='outline-primary' onClick={() => setShowLeftDrawer(true)}>Maler og produkter</IcebergButton>

				{/* Button to open Right Drawer */}
				<IcebergButton icon="brush" className='w-50' variant='outline-primary' onClick={() => setShowRightDrawer(true)}>Farger og logo</IcebergButton>
			</div>
			{/* Left Drawer */}
			<Offcanvas
				show={showLeftDrawer}
				onHide={() => setShowLeftDrawer(false)}
				placement="start" // Slide in from the left
			>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Maler og produkter</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<LeftCol
						onTemplateSelected={handleTemplateSelected}
						onProductDataChanged={handleProductDataChanged}
						productData={templateData.productData}
						onAngleSelected={handleAngleSelected}
					/>
				</Offcanvas.Body>
			</Offcanvas>

			{/* Right Drawer */}
			<Offcanvas
				show={showRightDrawer}
				onHide={() => setShowRightDrawer(false)}
				placement="end" // Slide in from the right
			>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Farger og logo</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<RightCol
						onDesignDataChanged={handleDesignDataChanged}
						contactInfo={templateData.contactInfo}
						onContactInfoChanged={handleContactInfoChanged}
					/>
				</Offcanvas.Body>
			</Offcanvas>



		</div>
	);
}

export default Designer;
