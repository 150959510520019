import "../TemplateBaseStyling.scss";
import Logo from "../components/Logo";
import { TemplateData } from "../../TemplateData";
import { generateBarcode } from "../../../../Shared/BarcodeGenerator";
import MovableElement from "../../../../components/MovableElement";
import { AutoTextSize } from "auto-text-size";
import { useEffect, useState } from "react";
import { IdentifierType, ProductData } from "../../ProductItem";
import ProductCard from "./ProductCard";
import { Template } from "../../Components/LeftCol";

interface Props {
  templateData: TemplateData;
  selectedTemplate?: Template | undefined;
}

const HorizontalCardTemplate = (props: Props) => {
  const defaultTableData: ProductData = {
    identiferType: IdentifierType.EPD_NR,
    products: [
      {
        produktnavn: "Produkt 1",
        epdNr: "1111111",
        basegtins: [],
        strekkodeBase64Url: generateBarcode("123456"),
        unitSmallPreviewImageGuid: undefined,
        angle: undefined,
        orderableUnitGtin: undefined,
        barcodeInput: "EPD NR",
        imageGtin: "123456",
        totalBaseUnitInOrderableUnit: 1
      },
      {
        produktnavn: "Produkt 2",
        epdNr: "2222222",
        basegtins: [],
        strekkodeBase64Url: generateBarcode("2222222"),
        unitSmallPreviewImageGuid: undefined,
        angle: undefined,
        orderableUnitGtin: undefined,
        barcodeInput: "EPD NR",
        imageGtin: "2222222",
        totalBaseUnitInOrderableUnit: 2
      },
      {
        produktnavn: "Produkt 3",
        epdNr: "333333",
        basegtins: [],
        strekkodeBase64Url: generateBarcode("333333"),
        unitSmallPreviewImageGuid: undefined,
        angle: undefined,
        orderableUnitGtin: undefined,
        barcodeInput: "EPD NR",
        imageGtin: "33333",
        totalBaseUnitInOrderableUnit: 3
      },
      {
        produktnavn: "Produkt 4",
        epdNr: "44444",
        basegtins: [],
        strekkodeBase64Url: generateBarcode("44444"),
        unitSmallPreviewImageGuid: undefined,
        angle: undefined,
        orderableUnitGtin: undefined,
        barcodeInput: "EPD NR",
        imageGtin: "44444",
        totalBaseUnitInOrderableUnit: 4
      },
    ]
  };

  const fontFamily = props.templateData.designData.fontFamily
    ? props.templateData.designData.fontFamily
    : "inherit";

  const [titleSize, setTitleSize] = useState({ width: 300, height: 50 });
  const [subTitleSize, setSubTitleSize] = useState({ width: 300, height: 30 });

  // reset sizes and positions when template changes
  useEffect(() => {
    setTitleSize({ width: 300, height: 50 });
    setSubTitleSize({ width: 300, height: 30 });
  }, [props.selectedTemplate]);

  return (
    <div
      id="template"
      className="template-container template-container-horizontal"
      style={{
        color: props.templateData.designData.fontColor ?? "#000",
        backgroundColor:
          props.templateData.designData.backgroundColor ?? "#fff",
        fontFamily: fontFamily,
        backgroundImage: props.templateData.designData.backgroundImageUrl
          ? `url(${props.templateData.designData.backgroundImageUrl})`
          : undefined,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >     
      {props.templateData.showDemoText && (
        <h1 className="watermark">Demo</h1>
      )}
      <div className="dynamic-area">
        <div>
          <Logo
            fontFamily={fontFamily}
            fontColor={props.templateData.designData.fontColor ?? "#000"}
            logoText={props.templateData.designData.logoText}
            logoFileName={props.templateData.designData.logoFileName}
            fontSize={props.templateData.designData.logoFontSize ?? 16}
            templateId={props.selectedTemplate?.id}
            position={{x: -550, y: -10}}            
          />
          {props.templateData.designData.title && (
          <div
            style={{ zIndex: "1000" }}
          >
            <MovableElement 
              size={titleSize} 
              top={-10}
              >
              <AutoTextSize
                mode="box"
                minFontSizePx={10}
                maxFontSizePx={100}
                fontSizePrecisionPx={0.1}
                style={{
                  fontFamily: fontFamily,
                  color: props.templateData.designData.fontColor ?? "#000",
                }}
              >
                <div contentEditable>{props.templateData.designData.title}</div>
              </AutoTextSize>
            </MovableElement>
          </div>
        )}
        {props.templateData.designData.subtitle && (
          <div
            style={{ height: "30px", zIndex: "1000" }}
          >
            <MovableElement 
            size={subTitleSize}
            position={{x: 250, y: 0}}>
              <AutoTextSize
                mode="box"
                minFontSizePx={10}
                maxFontSizePx={100}
                fontSizePrecisionPx={0.1}
                style={{
                  fontFamily: fontFamily,
                  color: props.templateData.designData.fontColor ?? "#000",
                }}
              >
                <div contentEditable>
                  {props.templateData.designData.subtitle}
                </div>
              </AutoTextSize>
            </MovableElement>
          </div>
        )}
        </div>

        <div className="section product-card-section">
          <ProductCard            
            productData={              
              props.templateData.productData &&
              props.templateData.productData.products.length > 0
                ? props.templateData.productData
                : defaultTableData
            }
            topItems={props.selectedTemplate?.maxProductCount}
          ></ProductCard>
        </div>
      </div>

      <div className="section mt-3 contact-info-section">
        {props.templateData.contactInfo.name && (
          <div className="text-center">
            {props.templateData.contactInfo.name}
          </div>
        )}
        {props.templateData.contactInfo.email && (
          <div className="text-center">
            {props.templateData.contactInfo.email}
          </div>
        )}
        {props.templateData.contactInfo.phone && (
          <div className="text-center">
            {props.templateData.contactInfo.phone}
          </div>
        )}
      </div>
    </div>
  );
};

export default HorizontalCardTemplate;
