export enum IdentifierType {
    GTIN = 'GTIN',
    COOP_NR = 'COOP NR',
    EPD_NR = 'EPD NR'
}

export interface ProductData {
    identiferType: IdentifierType
    products: ProductItem[]
}

export interface ProductItem {
    produktnavn: string
    epdNr: string
    basegtins: string[]
    barcodeInput?: string;
    identifierName?: string
    identifierValue?: string
    unitSmallPreviewImageGuid?: string | undefined
    strekkodeBase64Url?: string
    angle?: string | undefined
    mediaID?: number | null | undefined
    orderableUnitGtin?: string | undefined
    coopNumber?: string | undefined
    imageGtin: string
    totalBaseUnitInOrderableUnit: number
}