import { useEffect, useState } from "react";
import MediastoreMediaApi from "../../../../Services/MediastoreMediaApi";
import { IcebergColors, IcebergIcon } from "@tradesolution/iceberg-ui-react";

interface Props {
  widthInPx: number;
  heightInPx: number;
  gtin: string | null | undefined;  
}

const ThumbnailProductImage = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [blob, setBlob] = useState<Blob | null>(null);
  
  const loadPackageFromMediastore = async (gtin: string) => {    
    let response = await MediastoreMediaApi.packages.byGtin(gtin); 
    return response;
  };

  const loadImageFromMediastore = async (gtin: string) => {
    setIsLoading(true);
    try {
      const imagePackage = await loadPackageFromMediastore(gtin);
      const response = imagePackage.productImages.find((img) => img.isMainImage);
      if (response) {
        // we need the actual image dimensions to create a proper Rnd container
        if(!response.previewPngBlobId)
        {
          setError("Mangler bilde");          
        }
        let blobResponse = await MediastoreMediaApi.imageBlobPng.get(response.previewPngBlobId);        
        setBlob(blobResponse);
      }
    } catch (error: any) {
      console.error("Error fetching image from MediastoreApi");
      setError("Mangler bilde");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setError(undefined);
    setIsLoading(false);    
    if (props.gtin) {
      loadImageFromMediastore(props.gtin);
    } else {
      setBlob(null);
    }
  }, [props.gtin]);
    
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",        
        width: `${props.widthInPx}px`,
        height: `${props.heightInPx}px`,        
      }}
    >
      {error && <div><IcebergIcon icon="no-photo" size="2x" color={IcebergColors.GY200}/></div>}
          {isLoading && !error && <div>Henter bilde...</div>}
          {!isLoading && !error && (
            <img
              className="product-image"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              src={                
                  blob ?                   
                  URL.createObjectURL(blob) :
                  ""
              }
            />
          )}      
    </div>
  );
};

export default ThumbnailProductImage;
